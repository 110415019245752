//Import Splide js
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const options = {
	type: 'slide',
	perPage: 3,
	//gap: 20,
    arrows: false,
	pagination: true,
	drag:true,

    gap: 80,
	trimSpace: false,
	padding: {
		//left : '4rem',
		right: '9rem',
	},
    mediaQuery: 'max',
    breakpoints: {
		1600: {
		    perPage: 2,
		},
        768: {
			perPage: 1,
			gap: 20,
			padding: {	
				right: '4rem',
			},
		},
    }                  
}

export default class SplideGallery {    

    static collectionHome(target){
        let splide =  new Splide( target , options)
        splide.mount();
    }   
    		
	static headerHome(target){
        let splide =  new Splide( target , {
			type: 'loop',
			//autoplay: true,
			//interval:1000,
			perPage: 1,
			//gap: 20,
			arrows: false,
			pagination: true,
			//drag:true,
			autoScroll: {
				speed: .5, /*1.5 */
			},
			padding: {
				//left : '4rem',
				right: '9rem',
			},
			gap: '8.16%',
			//trimSpace: false,
			mediaQuery: 'min',
			breakpoints: {
				981: {
					destroy: true,
				},
				481: {
					perPage: 2,
					padding: {	
						right: '4rem',
					},
				},
			}                  
		})
        splide.mount({ AutoScroll });
    }

	//OTHER PRODUCTS GALLERY
	static otherProducts(target){
		let splide =  new Splide( target , {
			type: 'slide',
			perPage: 1,
			//gap: 20,
			arrows: false,
			pagination: true,
			drag:true,
			gap: '8.16%',
			//trimSpace: false,
			//padding: {
				//left : '4rem',
				//right: '9rem',
			//},
			mediaQuery: 'min',
			breakpoints: {
				981: {
					destroy: true,
				},
				980: {
					perPage: 4,
				},
				768: {
					perPage: 3,
				},
				481: {
					perPage: 2,
				},
			}                  
		})
        splide.mount();
	}

	//productDetail
	static productDetail(target){
		let splide =  new Splide( target , {
			type: 'slide',
			perPage: 4,
			//gap: 20,
			arrows: false,
			pagination: true,
			drag:true,
			gap: 40,
			//trimSpace: false,
			//padding: {
				//left : '4rem',
				//right: '9rem',
			//},
			mediaQuery: 'max',
			breakpoints: {
				980:{
					perPage: 3,
				},
				768: {
					perPage: 2,
				},
				480: {
					perPage: 1,
				},
			}                  
		})
        splide.mount();
	}

}